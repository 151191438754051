.terms{
    padding: 10px 0 ;

    
    .terms-container{
        width: 85%;
        margin: 0 auto;

        .terms-head{

        }
        .terms-content{
            
            h2{

            }
            p{
                padding: 10px 0;
                text-align: justify;
                font-size: 15px;
            }

        }
    }
}