.privacy{
    padding: 10px 0 ;

    
    .privacy-container{
        width: 85%;
        margin: 0 auto;

        .privacy-head{

        }
        .privacy-content{

            h2{

            }
            p{
                text-align: justify;
                padding: 10px 0;
                font-size: 15px;
            }

        }
    }
}