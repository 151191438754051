.why{
    padding: 20px 0;
    .why-container{

        .why-head{
            padding: 10px 0;
            text-align: center;

            h1{

            }
        }
        .why-content{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            width: 90%;
            margin: 0 auto;

            .content1{
                text-align: center;
                padding: 15px 0;

                h3{

                }
                img{
                    width: 130px;
                }
            }
        }
    }
}

@media (min-width:320px){
    .why-content{

        .content1{
            width: 50%!important;

        }
    }
    
    
}
@media (min-width:600px){
    .why-content{

        .content1{
            width: 25%!important;

        }
    }
    
  

}
@media (min-width:1024px){
    
    .why-content{

        .content1{
            width: 25%!important;

        }
    }
}