.founder{

    .founder-head{
        width: 85%;
        margin: 0 auto;
        text-align: center;

        h2{
            font-size: 38px;
            padding: 20px 0;
        }

    }
    .founder-container{
        width: 85%;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .founder-img{
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 70%;
                border-radius: 0px 100px 0px 100px;

            }
        }
        .founder-text{

            .details{
                padding: 10px 0;
                font-size: 18px;
                line-height: 1.5;
                word-spacing: 3px;

                P{
                    padding: 10px 0;

                    span{
                        font-weight: 700;

                    }
                }

            }

            .social-media{
                p{
                    padding: 10px 0;
                    font-size: 24px;
                    font-weight: 700;
                }

                a{
                    text-decoration: none;
                    color: black;
                    i{
                    margin: 0 5px;
                    font-size: 44px;
                }
                .insta{
                    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
                    -webkit-background-clip: text;
                            /* Also define standard property for compatibility */
                            background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
                .whats{
                    color: #25D366;
                }
                .linkd{
                    color: #0072b1;

                }
                 }
            }

        }

    }
}

@media (min-width:320px){
    
    .founder-img{
        width: 100%;

    }
    .founder-text{
        width: 100%;

    }
    
    
}
@media (min-width:600px){
    .founder-img{
        width: 50%;

    }
    .founder-text{
        width: 50%;

    }
    
  

}
@media (min-width:1024px){
    
    .founder-img{
        width: 50%;

    }
    .founder-text{
        width: 50%;

    }
   
}