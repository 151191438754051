// .home{
//     width: 100%;
   
//     img{
//         width: 100%;
//         height: 80vh;
//     }
//     .slick-slider{
//         width: 100%;
//     }

//     .slick-next {
//         right: 21px;
//     }
//     .slick-prev {
//         left: 26px;

//         z-index: 1;
//     }
//     .slick-prev:before, .slick-next:before{
//         font-size: 20px;
//         color: black;
//     }
// }

.home{
    padding: 20px 0;

    .home-container{
        width: 85%;
        margin: 0 auto;
        text-align: center;

        .home-text{
            padding: 10px 0;

            h1{
                font-size: 44px;
                padding: 10px 0;
            }
            p{
                padding: 10px 0;
            }

        }
        .search-box{
            padding: 20px 0;
            margin: 0 auto;


        }
        .popular-search{
            padding: 15px 0;
            font-size: small;

            span{


            }
            a{
                text-decoration: none;
                margin: 10px 5px;
                background-color: rgba(128, 128, 128, 0.1);
                border-radius: 50px;
                padding: 3px;
                color: black;
                transition: all 1s ease-in-out;
            }
            a:hover{
                background-color: aquamarine;

            }
        }

        .home-text1{
            padding: 20px 0;


            h1{
                font-size: 40px;
            }

            .row{
                width: 80%;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                padding: 20px 0;
                

                .col{
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .col1{
                        width: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        i{
                            font-size: 33px;
                            font-weight: 500;
                        }


                    }
                    .col2{
                        text-align: left;

                        p{
                            padding: 10px 0;
                        }


                    }
                }
            }
        }

    }
}
@media (min-width:320px){
    .search-box{
        width: 90%;

    }
    .col{
        width: 90%;
    
    }

}
@media (min-width:600px){
    .search-box{
        width: 50%;

    }
    .col{
        width: 50%;
    
    }
}
@media (min-width:1024px){
    .search-box{
        width: 30%;

    }
    .col{
        width: 33.33%;
    
    }
}