.footer{
    background-color: black;
    color: white;
    padding: 40px 0;
    .footer-container{
        width: 85%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;

        .logo{
            width: 100%;
            margin-bottom: 20px;

            .logo-container{
                margin-left: -11px;

                img{
                    width: 100%;


                }
            }
        }
        

        .footer-card{
            
            img{
                width: 150px;
                margin-top: -24px;
                
            }
          
            p{
                padding: 10px 0;
            }
            h4{
                color: #84c225;
                padding: 15px 0px;
                font-weight: 100;
            }
            
            .footer-icons{
                display: flex;
                gap: 2%;
                .footer-icon{
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    color: white;
                    border:2px solid #ddd;  
                }
                .facebook{
                    background-color: #3b5998;
                    
                }
                .facebook:hover{
                    color: #3b5998 ;
                    background-color: white;
                    border:2px solid #3b5998;
                }
                .pinterest{
                    background-color: #db3236;
                }
                .pinterest:hover{
                    color: #db3236;
                    background-color: white;
                    border: 2px solid #db3236;
                }
                .twitter{
                    background-color: #00aced;
                }
                .twitter:hover{
                    color: #00aced;
                    background-color: white;
                    border: 2px solid #00aced;
                }
                .instagram{
                    background-color: #517fa4;
                }
                .instagram:hover{
                    color: #517fa4;
                    background-color: white;
                    border: 2px solid #517fa4;
                }
            }
        }
        .lists{

            h4{

            }
            .list{
                margin: 15px 0;
                margin-left: 10px;
                p{
                    padding: 3px 0;
                    font-size: 20px;
                    font-variant: all-small-caps;
                
                    
                    a{
                        color: white;
                        text-decoration: none;
                    }
                }
            }
        }

        .quick-links{
            
            ul{
                line-height: 2.5;
                li{
                    list-style-type: none;
                    
                    cursor: pointer;
                    a{
                        text-decoration: none  ;
                        color: white;
                    }
                }
                
            }


        }
        .usefull-links{
            
            ul{
                line-height: 2;
                li{
                    list-style-type: none;
                
                    cursor: pointer;

                    a{
                        text-decoration: none  ;
                        color: white;
                    }
                }
                
            }
            
        }
        .address{
           
            h3{
                font-size: 14px;

            }
            a{
                text-decoration: none;
                font-size: 14px;
                color: white;
                padding-top: 15px;
                display: block;

                i{
                    padding-right:10px;
                }
            }

        }
       
    }
}
@media (min-width:320px){
        .res-w{
            width: 100%;
            
        }
        .logo-container{
            width: 50%;
        }

}
@media (min-width:600px){
    .res-w{
        width: 50%;
        
    }
    .logo-container{
        width: 50%;
    }

}
@media (min-width:1024px){
    .res-w{
        width: 25%;
        margin-bottom: 20px;
        
    }
    .logo-container{
        width: 20%;
    }

}