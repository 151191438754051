.searchbox{

    input{
        position: relative;


        table{
            position: absolute;
            top:0;
            border-collapse: collapse;
        }
    }


    
}