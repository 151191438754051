*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  
}
body{
  font-family: 'Roboto', sans-serif;
  text-align: justify;

  
}
p{
  line-height: 22px;
}
