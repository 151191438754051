.banking{
    padding: 20px 0;

    .banking-container{
        width: 85%;
        margin: 0 auto;

        .banking-head{
            text-align: center;

        }
        .banking-content{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            padding: 20px 0;

            .content1{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;

                img{
                    width: 50%;
                    transition: all .5s ease-in-out;
                }
                img:hover{
                    scale: 1.1;
                }
            }
        }

    }
}
@media (min-width:320px){
    .banking-content{

        .content1{
            width: 50%!important;
        }
    }
    
    
}
@media (min-width:600px){
    .banking-content{

        .content1{
            width: 25%!important;
        }
    }
    
  

}
@media (min-width:1024px){
    .banking-content{

        .content1{
            width: 25%!important;
        }
    }
    
   
}