.signup{


    .signup-container{
        width: 100%;
        height: 100vh;
        margin: 0 auto;
        
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .signup-img{
            width: 50%;

            display: flex;
            justify-content: right;
            align-items: center;
            img{
                width: 90%;
            }



        }
        .signup-form{
            width: 50%;

            .form-data{
                width: 90%;
                height: 100vh;
                display: flex;
                justify-content: center;
                align-items: center;


                form{

                    .form-head{
                        text-align: center;
        
                    }

                    .input1{
                        margin: 10px 0;
                        border: 1px solid black;
                        border-radius: 5px;

                        i{
                            font-size: 20px;
                            padding: 0 20px;
                        }

                        input{
                            border: none;
                            padding: 10px 5px;
                            border-radius: 30px;
                            font-size: 20px;
                            
                        }
                        input:focus{
                            outline: none;
                        }
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                          -webkit-appearance: none;
                          margin: 0;
                        }
                        

                    }
                    .btn{
                        width: 50%;
                        margin: 0 auto;
                        font-size: medium;
                        
                        
                        button{
                            width: 100%;
                            padding: 10px;
                            color: white;
                            background-color: #1eaeff;
                            border-radius: 5px;
                            border: none;
                            font-weight: bold;
                            cursor: pointer;
                        }
                        
                    }

                    .link{
                        text-align: center;
                        margin:15px 0 ;

                        a{
                            text-decoration: none;
                            color: black;
                            padding: 10px 0;
                        }
                    

                    }
                }

        
            }


        }
    }


}