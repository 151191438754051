.about{

    .about-container{
        width: 85%;
        margin: 0 auto;

        .about-head{


        }
        .about-content{

            .content1{
                width: 100%!important;
                display: flex;
                flex-wrap: wrap;
                

                .text{
                    padding: 10px 0;

                    p{
                        font-size: 16px;
                        padding: 10px 0;
                        text-align: justify;
                    }
                   
                    img{
                        width: 100%;
                    }
                }
            }
            .content2{
                width: 100%!important;
                h2{
                    text-align: center;
                }
                p{
                    text-align: justify;

                }
            }
            
        }
        
    }
    .about-reach{
        background-color: rgba(127, 255, 212, 0.349);
        padding: 30px 0;
        
        .col{
            width: 85%;
            margin: 0 auto;
            
            .row1{
                h1{
                    font-size: 40px;

                }
                h3{
                    font-size: 25px;
                    font-weight: 400;

                }
            }
            .row2{
                display: flex;
                flex-wrap: wrap;
                padding: 20px 0;
                div{
                    
                    i{
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        font-size: 20px;
                        border-radius: 5px;
                        color: white;
                        
                        
                    }
                    a{
                        margin: 0 10px;
                        font-size: 18px;
                        text-decoration: none;
                        color: black;
                    }
                    .mail{
                        background-color: sienna;
                    }
                    .phone{
                        background-color: darkslateblue;
                    }
                    .whatsapp{
                        background-color: green;
                    }
                    .web{
                        background-color: goldenrod;
                    }
                }


            }
            .row3{
                display: flex;
                flex-wrap: wrap;
                padding: 20px 0;
                div{
                    padding: 10px 0;
                    i{
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        font-size: 20px;
                        border-radius: 5px;
                        color: white;
                        
                        
                    }
                    p{
                        display: inline;
                        margin: 0 10px;
                        font-size: 18px;
                        color: black;
                    }
                    .check{
                        background-color: orangered;
                    }
                    .user{
                        background-color: darkslateblue;
                    }
                    


            }

        }


    }
    }
}

@media (min-width:320px){
    .text{
        width: 100%;

    }
    
    
}
@media (min-width:600px){
    .text{
        width: 50%;

    }
    
  

}
@media (min-width:1024px){
    .text{
        width: 50%;

    }
   
}