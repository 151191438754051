.search{
  .searchInputs {
 
    display: flex;

    input {
      background-color: white;
      border: 0;
      border-radius: 50px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      font-size: 18px;
      padding: 15px;
      height: 30px;
      width: 300px;
      position: relative;
    }
    .searchIcon {
  
      width: 50px;
      display: grid;
      place-items: center;
    }
    
    input:focus {
      outline: none;
    }
    .searchIcon svg {
      font-size: 35px;
    }


  }
  .dataResult {
    position: absolute;
    margin-top: 5px;
    width: 300px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;

    .dataResult::-webkit-scrollbar {
      display: none;
    }

    .dataItem {
      width: 100%;
      height: 50px;
      display: flex;
      align-items: center;
      color: black;
      text-decoration: none;

      p {
        margin-left: 10px;
      }
      
      #clearBtn {
        cursor: pointer;
      }

    }
    a:hover {
      background-color: lightgrey;
    }

  }

}
