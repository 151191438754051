.services{
   

    .services-container{
        width:85% ;
        margin: 0 auto;

        .services-head{
            padding: 30px 0;
           
            
            h1{


            }

        }
        .services-content{
            

            .dipartment-right p{
                font-size:16.5px;
                color:grey;
            }
            .dipartment-right i{
                font-size:16.5px;
                color:grey;
            }
            .dipartment-cards{
                background-color:#f7fcfc;
                border-left:5px solid #3fbbc0;
            }
            .dipartment-left p{
                font-size:15px;
            }
            .dip-card{
                border:none !important;
            }
            #list-tab .active{
                background-color:#f7fcfc;
                border-left:5px solid #3fbbc0 !important;
            }
            #list-tab .active p{
                color: black !important;
            }
            #list-tab .active h5{
                color: #3fbbc0 !important;
            }
            .list-hover:hover h5{
                color:#3fbbc0;
            }
            .tab-pane h3{
                color:#3fbbc0;
            }
            
            
           
        }

    }

}
@media (min-width:320px){
    
}
@media (min-width:600px){
    

}
@media (min-width:1024px){
    

}