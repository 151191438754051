.gst{

    .gst-container{
        

        .gst-content1{
            width: 100%;
            background: url(../../assets/img/gstbg.jpg) rgb(0, 0, 0);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;


            .gst-content1-container{
                width: 85%;
                margin: 0 auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                color: white;
                padding: 20px 0;


                .content1{

                    h1{
                        font-size: 40px;
                    }
                    p{
                        font-size: 20px;
                        padding: 10px 0;
                    }
                    
                }
                .content2{
                    display: flex;
                    align-items: center;
                    justify-content: center;


                    .form-box{

                        width: 80%;
                        background-color: white;
                        border-radius: 10px;

                        input{
                            border: none;
                            border-bottom: 1px solid gray;
                            border-radius: 0;

                        }
                        
                        .btn{
                            background-color: #1eaeff;
                            border: none;
                            margin: 10px auto;
                            border-radius:8px;
                            font-size: 20px;
                            font-weight: 500;
                        }

                    }


                }
                .content3{
                    width: 100%;
                    h2{
                        color: #1eaeff;
                    }

                    .content3-row{
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        padding: 20px 0;

                        .content3-col1{
                            ul{

                                li{
                                    padding: 5px 0;
                                }
                            }


                        }
                     
                    }
                }
            

            }

        }
        .gst-content2{
            width: 100%;
            height: 40vh;

            .gst-content2-container{

                .content1{


                }
                .content2{


                }
                .content3{

                }

            }

        }
        .gst-content3{

            .gst-content3-container{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;

                .page_sect_cont{
                    
                }

                .content1{
                    position: sticky;
                    margin-top: 30px;
                    top: 110px;
                    height: 100%;
                    width: 25%;
                    text-align: center;
                    margin-left: auto;
                    
                    .content1-container{
                       ul{
                        list-style: none;
                        margin: 0 20px;
                        li {
                            font-weight: 500;
                            padding: 10px;
                            margin: 20px 10px;
                        
                            
                            &.active-side-nav {
                                background-color: #1eaeff;
                                color: white;
                            }
                          }
                        }

                    }

                }
                .content2{
                    

                    .content2-c1{

                        h2{
                            padding: 10px 0;


                        }
                        p{
                           padding:  10px 0;
                        }
                        h3{
                            padding: 5px 0;
                        }
                        ul{
                            margin-left: 25px;

                            li{
                                padding: 10px 0;
                            }

                        }
                    }


                    .content2-accordion{
                        padding: 10px 0;

                        h2{
                            padding: 10px 0;
                        }

                        .css-o4b71y-MuiAccordionSummary-content:hover{
                            
                            z-index: 20;
                       
                        }
                        .css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
                            margin: 10px 0; 
                        }  

                    }



                }
                .content3{

                    position: sticky;
                    margin-top: 30px;
                    top: 110px;
                    height: 100%;
                    width: 25%;
                    text-align: center;
                    margin-left: auto;
                    
                    h3{
                        padding-top: 10px;
                    }

                    .form-box{
                        width: 80%;
                        margin:0 auto;
                        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.342);
                        border-radius: 5px;
                        
                        
                        input{
                            border: none;
                            border-bottom: 1px solid gray;
                            border-radius: 0;
                            margin-top: 10px;
                        }
                        
                        input:focus{
                            outline:none;
                           }
                        .btn{
                            background-color: #1eaeff;
                            border: none;
                            margin: 10px auto;
                            border-radius:10px;
                            font-size: 20px;
                            font-weight: 500;
                            width: 50%;
                        }

                    }

                }

            }

        }
    }
}


@media (min-width:320px){
    .content1 {
        width: 100%!important;
    }
    .content2{
        width: 100%!important;
    }
    .content3-col1{
        width: 100%;

    }
    .gst-content3{

    .gst-content3-container{

        .content1{
            width: 100%!important;

        }
        .content2{
            width: 100%!important;
            margin: 0 20px!important;
        }
        .content3{
            width: 100%!important;
        }
        }

    }
    
}
@media (min-width:600px){
    
    .content1 {
        width: 50%!important;
    }
    .content2{
        width: 50%!important;
    }
    .content3{

    }
    .gst-content3{

    .gst-content3-container{

        .content1{
            width: 25%!important;

        }
        .content2{
            width: 50%!important;
            margin: 0!important;

        }
        .content3{
            width: 25%!important;
        }

    }
    
    }

}
@media (min-width:1024px){
    .content1 {
        width: 50%!important;
    }
    .content2{
        width: 50%!important;
    }
    .gst-content3{

    
    .gst-content3-container{

        .content1{
            width: 25%!important;

        }
        .content2{
            width: 50%!important;
            margin: 0!important;

        }
        .content3{
            width: 25%!important;
        }

    }
    }
   
}