.App {
    font-family: sans-serif;
    text-align: center;
  }
  .form{
    display: flex;
    flex-direction: column;
    padding: 20px
  }
  
  .form input {
    height: 50px;
    margin-top: 20px;
    border-radius: 10px;
    padding: 5px;
  
  }
  .form textarea {
    margin-top: 20px;
    border-radius: 10px;
    padding: 5px;
  
  }
  
  .btn{
    width: 30%;
    border: none;
    border-radius: 8px;
    background-color: #49b5e7;
    cursor: pointer;
    font-size: medium;

  }
  .btn:hover{

    background-color: #007aff;
  }