.service-details{
    padding: 20px 0;
    .service-details-container{
        width: 85%;
        margin: 0 auto;
        text-align: justify;

        .service-details-content1{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            
            .details1{ 
                margin: 10px 0px;
                
                .details-border{
                    box-shadow: 0px 0px 5px rgba(128, 128, 128, 0.801);
                    border-radius: 20px;
                    padding: 40px;

                    h2{
                        padding-bottom: 10px;

                    }

                    p{
                        padding: 10px 0;
                        font-size: 15px;
                        
                       
                    }
                }

            }
            .details2{
                margin: 10px 0px;
                display: flex;
                justify-content: center;
                flex-direction: column;

                .price{
                    padding: 16px;
                    width: 70%;
                    margin: 0 auto;
                    margin-bottom: 10px;
                    border-radius: 15px;
                    background-color: #03a9f4;
                    color: white;  
                    
                    p{

                        span{
                            color:red;
                        }
                    }
                }
                .formdiv{
                    
                    
                    .forms{
                        width: 70%;
                        margin: 0 auto;
                        background: #fff;
                        border-radius: 20px;
                        box-shadow: 0 0 16px 0 rgb(0 0 0/50%);
                        input{
                            border: 0;
                            width: 100%;
                            height: 42px;
                            font-family: Poppins,sans-serif;
                            padding-left: 20px;
                            font-size: 14px;
                            background: #f3f3f3;
                        }
                        .btn{
                            background-color: #03a9f4;
                            color: #fff;
                            width: 50%;
                            margin: 0 auto;
                            border-radius: 50px;
                            padding: 10px 0;
                            margin-top: 15px;
                        }
                        p{
                            font-size: 12px;
                            text-align: center;
                        }
                    }


                }





            }

        }
        .service-details-content2{

            .details1{
                width: 100%;

            }
        }

    }
   

}

@media (min-width:320px){
    .details1{ 
        width: 100%;
    }
    .details2{
        width: 100%;
    }

}
@media (min-width:600px){
    .details1{ 
        width: 50%;
    }
    .details2{
        width: 50%;
    }

}
@media (min-width:1024px){
    .details1{ 
        width: 60%;
    }
    .details2{
        width: 40%;
    }

}