.client{
    padding: 30px 0;

    .client-container{
        width: 85%;
        margin: 0 auto;

        .client-head{
            text-align: center;
           
            h1{
                padding: 20px 0;
                color: #000;
                font-size: 50px;
                font-style: normal;
                font-weight: 700;
                line-height: 34px; /* 68% */
                letter-spacing: 2px;

            }
                
        }
        .client-carousel {
          padding: 20px;
         
          .client-logo {
            display: flex!important;
            justify-content: center!important;
            align-items: center!important;
            height: 30vh;
            padding: 20px;
          }
          
          .client-logo img {
            max-width: 100%;
            max-height: 100px;
            margin: 0 auto;
          }
    
          

    }
}
}


  
  
  