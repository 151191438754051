
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #034d82!important;
    background-color: white!important;
    font-size: 24px!important;
}

.css-agdkjh-MuiTabs-scrollbarSize {
    width: 99px!important;
height: 50px!important;
position: inherit!important;; 

}
.css-lfwcke-MuiTabs-flexContainer {
   

}
.css-35zqo5-MuiTabs-root {  
    
}             

.css-35zqo5-MuiTabs-root {
    background-color: #034d82!important;
    border: none!important;
    border-radius: 10px!important;
}
.css-10d9dml-MuiTabs-indicator {
    background-color: white!important;

}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    color: white!important;
    font-weight: 600!important;
    font-size: 24px!important;
}
.css-1n3h4wo {
 
    height: max-content!important;
}
.css-1n3h4wo {
    flex-wrap: wrap!important;

}
.container{
    width: 100%;
 
}
.row{
    display: flex;
    flex-wrap: wrap;
    .col-p{;
        padding: 10px;


        .col{
            // background-color: rgba(120, 228, 228, 0.726);
            width: 100%;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
            border-radius: 5px;
            padding: 10px;
            transition: all .5s cubic-bezier(0.075, 0.82, 0.165, 1);
            

            h2{
                text-align: left;
                height: 10vh;

            }
            p{
                padding: 10px 0;
                
            }
            a{
                text-decoration: none;
                font-size: 33px;
                color: #034d82;
                margin-left: 86%;
            }

        }
        .col:hover{
            box-shadow: 0px 0px 12px  rgba(0, 0, 0, 0.5);
            transform: translateY(-10px);

        }
    }


}

@media (min-width:320px){

    css-35zqo5-MuiTabs-root {
        width: 100%!important;
        margin-right: 10px!important;
    }
    .container {
        width: 100%;
    }
    .col-p{
        width: 100%;

    }
}
@media (min-width:600px){
    css-35zqo5-MuiTabs-root {
        width: 25%!important;
        margin-right: 10px!important;
    }
    .container {
        width: 62%;
    }
    .col-p{
        width: 50%;

    }

}
@media (min-width:1024px){
    css-35zqo5-MuiTabs-root {
        width: 30%!important;
        margin-right: 10px!important;
    }
    .container {
        width: 67%;
    }
    .col-p{
        width: 50%;

    }
}
