.plan{
    padding: 20px 0;
    background-color: #41dcf052;

    .plan-container{
        width: 85%;
        margin: 0 auto;

        .plan-head{
            padding: 30px 0;
            text-align: center;
        }
        .plan-content{
            display: flex;
            flex-wrap: wrap;

            .contentp{
                
                padding: 0 15px;
                margin: 20px 0;
                .content1{
                    width: 100%!important;
                    text-align: center;
                    padding: 10px;
                    background-color: white;

                    transition: all 0.5s ease-in-out;

                    h3{
                        padding: 10px 0;
                    }
                    hr{
                        color: rgba(128, 128, 128, 0.377);
                    }
                    h2{
                        padding: 15px 0;
                        span{
                            font-size: small;
                            font-weight: 100;
                        }
                    }

                    .list{
                        text-align: left;
                        font-size: 14px;
                        height: 43vh;
                        p{
                            padding: 5px 0;

                            i{
                                padding: 0 5px;
                                color: #0da2e7;
                            }
                        }

                    }
                    .btn-box{
                        display: flex;
                        justify-content: center;
                        text-decoration: none;

                        .buy-btn{
                            width: 50%;
                            border-radius: 10px;
                            padding: 10px 0;
                            background-color: #49b5e7;
                            color: white;
                        }
                    }


                }
                .content1:hover{
                    scale: 1.05;
                }
            }

        }
    }
}

@media (min-width:320px){
    .contentp{
        width: 100%;

    }

}
@media (min-width:600px){
    .contentp{
        width: 50%;
    }

}
@media (min-width:1024px){
    .contentp{
        width: 33.33%;
    }

}