.process{
    padding: 30px 0;
    
    .process-head{
        width: 85%;
        margin: 0 auto;
        text-align: center;
        padding: 20px 0;
    }
    .process-container{
        width: 85%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 0;


        .process-item{
            padding: 10px;
           display: flex;
           flex-direction: column;
           align-items: center;
           justify-content: center;
           text-align: center;
            background-color: rgb(30 174 255);
            color: white;
            border-radius: 10px;

            img{
                width: 130px;
                margin-top: -73px;
                transform: translate(6px, 1px);
            }
            h2{
                padding: 10px;
            }


        }
        .gbg{
            background-color: rgb(0, 158, 8);
        }


    }
}
@media (min-width:320px){
    .process-item{
        width: 98%;
        margin-top: 20%;
    }
}
@media (min-width:600px){
    .process-item{
        width: 48%;
        margin-top: 10%;
    }
  

}
@media (min-width:1024px){
    .process-item{
        width: 24%;
        margin-top: 0%;
    }
   
}